/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, YouTube } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Neon Mandala"} description={"Neon Mandala"}>
        <Column className="pb--60 pt--60" name={"3ve1p1i6eqx"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Sand Mandala"}>
              </Title>

              <Text className="text-box" content={"Jemná moderně jazzová skladba<br>Delightfull modern jazz composition"}>
              </Text>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box" use={"page"} href={"/"} content={"Home"}>
              </Button>

              <YouTube embedId={"zOlBD5TxpCU"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"wog8alfccxq"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Orange Mandala"}>
              </Title>

              <Text className="text-box" content={"Funk composition"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=HO8rk10DAKI&list=PLkTj2Nk6txuj3Z8ANgl65jA4sRdkCU_Yq&index=2&t=2s"} href={"https://www.youtube.com/watch?v=HO8rk10DAKI&list=PLkTj2Nk6txuj3Z8ANgl65jA4sRdkCU_Yq&index=2&t=2s"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Cloud Mandala"}>
              </Title>

              <Text className="text-box" content={"Greetings From The Sky cover<br>Cool jazz<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=uQ4Yljre8qQ&list=PLkTj2Nk6txuj3Z8ANgl65jA4sRdkCU_Yq&index=3"} href={"https://www.youtube.com/watch?v=uQ4Yljre8qQ&list=PLkTj2Nk6txuj3Z8ANgl65jA4sRdkCU_Yq&index=3"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Neon Mandala"}>
              </Title>

              <Text className="text-box" content={"Ice Bath cover<br>Dance<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=ZJwQ5p7InW8&list=PLkTj2Nk6txuj3Z8ANgl65jA4sRdkCU_Yq&index=4"} href={"https://www.youtube.com/watch?v=ZJwQ5p7InW8&list=PLkTj2Nk6txuj3Z8ANgl65jA4sRdkCU_Yq&index=4"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"5eirrunz29i"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Ebony Mandala"}>
              </Title>

              <Text className="text-box" content={"Tiptoe Elephant cover"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=WUjVGAACzD8&list=PLkTj2Nk6txuj3Z8ANgl65jA4sRdkCU_Yq&index=5"} href={"https://www.youtube.com/watch?v=WUjVGAACzD8&list=PLkTj2Nk6txuj3Z8ANgl65jA4sRdkCU_Yq&index=5"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--36" content={"Concrete Mandala"}>
              </Title>

              <Text className="text-box fs--14" content={"Temná struktura s jazz-popovým tématem<br>Dark matter with jazz-pop theme<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=bE7igXDwxNw&list=PLkTj2Nk6txuj3Z8ANgl65jA4sRdkCU_Yq&index=6"} href={"https://www.youtube.com/watch?v=bE7igXDwxNw&list=PLkTj2Nk6txuj3Z8ANgl65jA4sRdkCU_Yq&index=6"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Sun Mandala"}>
              </Title>

              <Text className="text-box" content={"Jiskřivá elektronická skladba<br>Sparkling electronic composition<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=ZS4p3c9aXwk&list=PLkTj2Nk6txuj3Z8ANgl65jA4sRdkCU_Yq&index=7"} href={"https://www.youtube.com/watch?v=ZS4p3c9aXwk&list=PLkTj2Nk6txuj3Z8ANgl65jA4sRdkCU_Yq&index=7"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ct8py17p3ze"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Golden Mandala"}>
              </Title>

              <Text className="text-box" content={"Lions Eat First cover"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"xm0_hjMdvXU"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"6iqv2ynawhq"}>
        </Column>

      </Layout>
    </ThemeWrapper>
  )
}